import React, { useEffect, useRef, useState } from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import pdfToText from "react-pdftotext";
import "./AdvancedSummary.css";
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ShareLinkComponent from "./ShareLinkComponent";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import GearGif from "../../../GearGif.gif";
import { background } from "@chakra-ui/react";
import { Margin } from "@mui/icons-material";
import UploadCloud from "../../../UploadCloud.png";
import styles from "./FileUpload.module.css";
import AWS from 'aws-sdk';
import * as pdfjsLib from 'pdfjs-dist';
// const pdfjs = await import('pdfjs-dist/build/pdf');
// const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




// import htmlToDocx from 'html-to-docx';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';



import { pdfjs } from 'react-pdf';

import MathList from "./Mathlist";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const smallTabStyle = {
  paddingLeft: "5px",
  paddingRight: "5px",
  Margin: "10px",
  borderRadius: "20px",
}




AWS.config.update({
  accessKeyId: 'AKIAZIEPTS2346N7HDXU',
  secretAccessKey: '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',
  region: 'eu-north-1',
});

//const S3_BUCKET = 'parvbucket12';
const S3_BUCKET = 'pdfing.ai';



export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: 'eu-north-1',
});

function FileUploadComponent({ setSelectedMath, hasMath, isSelectedMath, setWordsCount, type1Value, type2Value, urlStats, response, setResponse, file, setFile, fileUrl, setFileUrl, fileContent, setFileContent, isLoading, ocrProcessing,
  setIsLoading, setUploadedfile, setShowSummaryChatbot,useOCR,setUseOCR,
  callOverviewOnce, fileName, setFileName, uploadError, wordsCount, curSummaryname }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdown, setdropdown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [fileName,setFileName] = useState(null);
  const inputRef = useRef(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setdropdown(null)
  };
  const handleMenuClick = (event, index) => {

    setAnchorEl(event.currentTarget);
    setdropdown(!dropdown)
  };





  const uploadToS3 = async (file, filename) => {

    console.log("file name-----", filename)
    if (!file) return;



    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: 'application/msword',
    };


    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };

  async function convertPdfToPng(file) {


    if (file.type !== 'application/pdf') {
      throw new Error("File type is not PDF");
    }

    const reader = new FileReader();

    reader.onload = async function () {
      const typedArray = new Uint8Array(this.result);

      // Load PDF document using pdfjsLib
      const loadingTask = pdfjsLib.getDocument(typedArray);
      try {
        const pdf = await loadingTask.promise; // Wait for the PDF to load
        const numPages = pdf.numPages;

        alert(`Number of pages: ${numPages}`);

        for (let i = 1; i <= numPages; i++) { // Pages are 1-indexed
          const page = await pdf.getPage(i);
          const canvas = document.createElement('canvas');
          const viewport = page.getViewport({ scale: 1 });

          const ctx = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };

          await page.render(renderContext).promise; // Wait for rendering to complete

          canvas.toBlob(function (blob) {
            const link = document.createElement('a');
            link.download = `page-${i}.jpg`;
            link.href = URL.createObjectURL(blob);
            link.click();
          }, 'image/jpeg');
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
  }


  //   async function convertPdfToPng(file) {
  //     if (file.type !== 'application/pdf') {
  //         throw new Error("File type is not PDF");
  //     }

  //     // Load PDF document from file
  //     // const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
  //     const reader = new FileReader()
  //     reader.onload = function(){
  //       const typedArray = new Uint8Array(this.result)

  //       pdfjsLib.getDocument((typedArray).promise.then(function(pdf){
  //         const numPages = pdf.numPages

  //         alert(numPages)
  //         for (let i=0; i < numPages ; i++){
  //           pdf.getPage(i).then((page)=>{
  //             const canvas = document.createElement('canvas')
  //             const viewport = page.getViewport({scale:1})

  //             const ctx = canvas.getContext('2d')
  //             canvas.width = viewport.width
  //             canvas.height = viewport.height

  //             const renderContext ={
  //               canvasContext:ctx,
  //               viewport:viewport
  //             }

  //             page.render(renderContext).promise.then(function(){
  //               canvas.toBlob(function(blob){
  //                 const link = document.createElement('a')
  //                 link.download = 'page-' + i + '.jpg'
  //                 link.href = URL.createObjectURL(blob)
  //                 link.click()
  //               },'image/jpeg')
  //             })
  //           })
  //         }
  //       }))
  //     }


  //     reader.readAsArrayBuffer(file)

  //     // const pngImages = [];
  //     // for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //     //     const page = await pdf.getPage(pageNum);

  //     //     // Set scale for high-quality output (e.g., 2 for twice the resolution)
  //     //     const scale = 2;
  //     //     const viewport = page.getViewport({ scale });

  //     //     // Create a canvas to render the page
  //     //     const canvas = document.createElement('canvas');
  //     //     const context = canvas.getContext('2d');
  //     //     canvas.width = viewport.width;
  //     //     canvas.height = viewport.height;

  //     //     const renderContext = {
  //     //         canvasContext: context,
  //     //         viewport: viewport,
  //     //     };

  //     //     await page.render(renderContext).promise;

  //     //     // Convert canvas to PNG
  //     //     const pngDataUrl = canvas.toDataURL('image/png');
  //     //     pngImages.push(pngDataUrl);

  //     //     // Optional: if you need to clear or remove the canvas
  //     //     canvas.remove();
  //     // }

  //     // return pngImages;  // Array of PNG data URLs for each page
  // }




  const handleFileUpload2 = async (event) => {

    const file = event.target.files[0]; // Ensure only one file is uploaded
    if (!file) return;
    console.log("file is of type", file.type)
    // Determine ContentType and filename
    const fileType = file.type;

    if (isSelectedMath && file.type === 'application/pdf') {
      try {
        const pngs = await convertPdfToPng(file);  // Waits for the conversion
        console.log(pngs, "aqsw22");  // Logs the result once the conversion is done
      } catch (error) {
        console.error("Error converting PDF to PNG:", error);
      }
    }
    if (isSelectedMath && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')) {
      try {
        setIsLoading(true)
        setFileContent("parsedContent");
        setFile(event.target.files[0])
        setFileName(file.name);
        setFileUrl(URL.createObjectURL(file))
        const filename = `${Date.now()}_${file.name}`
        const url = await uploadToS3(file, filename); // Upload the file to S3 and get the URL
        // sets3FileUrl(url);
        console.log("file uploaded:", url)

        const formatType = file.type.split('/')[1]

        const response = await fetch('https://pdfing.ai:7000/do_mathematics', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "image_url": url,
            "format_type": formatType,
          }),
        });

        // Wait for the response and log it
        const responseData = await response.json();
        console.log("API response:", responseData);
        let temp = <MathList Items={responseData} />;
        console.log("response to rendr :", temp)
        setResponse(temp);
        setFileContent(JSON.stringify(responseData));
        setShowSummaryChatbot(true);

      } catch (error) {
        console.error("Error during file upload or API call:", error);
      }
      setIsLoading(false);
    }
    else {
      console.log("fileconten44", fileContent)
      const file = event.target.files[0];
      setFileName(file.name);
      setFile(event.target.files[0])

      setFileUrl(URL.createObjectURL(file))
      console.log(event.target.files[0])
      setIsLoading(true)
      setUploadedfile(file);
    }
    if (!file) return;
  };

  useEffect(() => {
    const processFile = async () => {
      if (
        hasMath &&
        file &&
        (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")
      ) {
        try {
          setIsLoading(true);
          console.log("hee22")
          setSelectedMath(true)
          // Set preliminary states
          setFileContent("parsedContent");
          setFileName(file.name);
          setFileUrl(URL.createObjectURL(file));

          // Upload the file to S3
          const filename = `${Date.now()}_${file.name}`;
          const url = await uploadToS3(file, filename); // Ensure uploadToS3 is properly defined
          console.log("File uploaded:", url);

          // Determine file format type
          const formatType = file.type.split("/")[1];

          // Send API request
          const response = await fetch("https://pdfing.ai:7000/do_mathematics", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              image_url: url,
              format_type: formatType,
            }),
          });

          // Handle API response
          const responseData = await response.json();
          console.log("API response:", responseData);

          // Update state based on response
          const temp = <MathList Items={responseData} />;
          console.log("Response to render:", temp);
          setResponse(temp);
          setFileContent(JSON.stringify(responseData));
          setShowSummaryChatbot(true);
        } catch (error) {
          console.error("Error during file upload or API call:", error);
        } finally {
          setIsLoading(false); // Always set loading to false in the `finally` block
        }
      }
    };

    if (file && !hasMath && (isSelectedMath === false)) {
      setShowSummaryChatbot(false)

      setFileUrl(URL.createObjectURL(file))
      setFileName(file.name);
      setUploadedfile(file);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const content = e.target.result;

        if (file.type === "application/pdf") {
          // For PDF files

          setIsLoading(true)
        //   if (useOCR.includes(file.name)) {
        //     // If the file already exists, call the OCR function
        //     console.log("OCR processing call:",useOCR);
        //     await handleOcrCall(file);
        // }
        console.log("OCR processing call:",useOCR);
          if (ocrProcessing) {
            console.log("ocr processing call:")
            await handleOcrCall(file)


          }
          else {
            console.log("ocr is off---")
            const loadingTask = pdfjs.getDocument({ data: content });
            const pdf = await loadingTask.promise;

            let pageTexts = "";

            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              const page = await pdf.getPage(pageNumber);
              const textContent = await page.getTextContent();
              const pageText = textContent.items.map(item => item.str).join(' ');

              pageTexts = pageTexts + (`Page ${pageNumber}:\n${pageText}`);
            }

            console.log("Split pages text: ", pageTexts);
            // pageTexts = pageTexts
            callOverviewOnce(pageTexts);
            setFileContent(pageTexts);  // Set the content split by pages

            // const text = await pdfToText(file);
            setWordsCount(pageTexts.trim().split(/\s+/).length)
            // callOverviewOnce(text);
            // setFileContent(text);
            // setIsLoading(false)
            // }


          }
        } else if (
          file.type === "application/msword" ||
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // For Word document files
          const doc = new Docxtemplater(new PizZip(content), {
            delimiters: {
              start: "12op1j2po1j2poj1po",
              end: "op21j4po21jp4oj1op24j",
            },
          });
          const parsedContent = doc.getFullText();
          setWordsCount(parsedContent.trim().split(/\s+/).length)
          console.log("doc file 22")
          console.log(parsedContent)
          setFileContent(parsedContent);
          callOverviewOnce(parsedContent);
        } else {
          setFileContent("");
          setErrorMessage(
            "Unsupported file format. Please upload a PDF or Document file."
          );
        }
      };

      reader.readAsArrayBuffer(file);
    } else {
      console.log("hee", hasMath)
      processFile();
    }

  }, [file]);



  const handleOcrCall = async (uploadedFile) => {
    console.log("CallinfOCR", uploadedFile)
    const apiLink = "https://pdfing.ai:7000/ocr";
    const formData = new FormData();
    formData.append('pdfFile', uploadedFile);


    const requestOptions = {
      method: "POST",
      body: formData,
    };



    try {
      // setIsLoading(true);
      let ocrResponse = await fetch(apiLink, requestOptions);
      let parsedOcrResponse = await ocrResponse.json();
      // setOcrResponse(parsedOcrResponse);
      console.log("ocr response: ", parsedOcrResponse.content)
      setFileContent(parsedOcrResponse.content)
      setWordsCount(parsedOcrResponse.content.trim().split(/\s+/).length)

      callOverviewOnce(parsedOcrResponse.content)


      // setIsLoading(false)
      console.log("ocr response", parsedOcrResponse)

    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  };




  const handleDownloadPDF = async () => {
    setdropdown(false);

    const input = inputRef;
    console.log(input);
    console.log(input.current);

    // const canvas = await html2canvas(input);
    // const imgData = canvas.toDataURL('image/png');
    // const pdf = new jsPDF();
    // const imgWidth = 210;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    // pdf.save(`${fileName}_summary_${type1Value}_${type2Value}.pdf`);
  };

  // const handleDownloadWord = async () => {
  //   setdropdown(false);

  //   const input = inputRef.current; // Your HTML content

  //   // Simple text extraction (adjust this as per your needs)
  //   const htmlContent = input.innerHTML; // Extract innerHTML

  //   // Create a new Document
  //   const doc = new Document({
  //     sections: [
  //       {
  //         children: [
  //           new Paragraph({
  //             children: [new TextRun(htmlContent)],
  //           }),
  //         ],
  //       },
  //     ],
  //   });

  //   // Generate the document as a Blob
  //   const blob = await Packer.toBlob(doc);

  //   // Save the file
  //   saveAs(blob, `${fileName}_summary_${type1Value}_${type2Value}.docx`);
  // };


  const wrapHtmlContent = (htmlContent) => {
    return `
        <!DOCTYPE html>
        <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
      `;
  };

  const handleDownloadDOC = (response, filename) => {
    setdropdown(false);

    const wrappedHtml = wrapHtmlContent(response);
    const blob = new Blob(['\ufeff', wrappedHtml], {
      type: 'application/msword',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}_summary_${curSummaryname.val1}_${curSummaryname.val2}.doc`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleCopy = (response) => {
    let html = `
      <!DOCTYPE html>
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head><meta charset='utf-8'><title>Document</title></head><body>${response}</body></html>
    `

    const container = document.createElement('div');
    document.body.appendChild(container);
    container.innerHTML = html;

    const textarea = document.createElement('textarea');
    textarea.value = container.innerText;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {

    }
    document.body.removeChild(textarea);
    document.body.removeChild(container);
  }

  // useEffect(() => {
  //   // Add the AdSense script to the document
  //   if (!document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  //     script.crossOrigin = 'anonymous';
  //     script.onload = () => {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     };
  //     console.log("addsense-script",script)
  //     document.head.appendChild(script);
  //   } else {
  //     // If the script is already present, initialize the ad slot
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   }}, []);


  const handlePageClick = (pageNum) => {
    // Create the URL for opening the PDF at the specific page
    const pdfPageUrl = `${fileUrl}#page=${pageNum}`;
    // Open the PDF in a new tab
    window.open(pdfPageUrl, '_blank');
  };


  const renderHtmlContent = (htmlContent) => {
    console.log("regexing:", htmlContent, "Type:", typeof htmlContent);

    // Ensure htmlContent is a string
    if (typeof htmlContent !== "string") {
      console.error("htmlContent is not a string:", htmlContent);
      return ""; // Return an empty string or a fallback value
    }
    console.log("regexing :", htmlContent)
    if (isSelectedMath) {
      return htmlContent;
    }
    // console.log(htmlContent.replace(/<p>(\d+)(?:[,-].*)?<\/p>/g, (match, firstNum,fullContent)=>{return `${match},${firstNum},${fullContent}`}))
    return htmlContent.replace(/<p>(\d+)(?:[,-].*)?<\/p>/g, (match, firstNum, fullContent) => {      // Replace <p> tags with clickable spans that open the PDF
      return `<span class="page-link" style="display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #0066cc;
    cursor: pointer; " onClick="window.handlePageClick(${firstNum})">Page &nbsp ${match}</span>`;
    });
  };

  const [supported_types, setSupported_types] = useState([{ name: "DOCX", color: "#B3ECEF" }, { name: "DOC", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])

  useEffect(() => {
    if (isSelectedMath === true) {
      setSupported_types([{ name: "JPG", color: "#B3ECEF" }, { name: "PNG", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])
    }
    else {
      setSupported_types([{ name: "DOCX", color: "#B3ECEF" }, { name: "DOC", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])
    }
  }, [isSelectedMath])


  // Attach the click handler globally
  window.handlePageClick = handlePageClick;

  console.log("window.adsbygoogle", window.adsbygoogle)
  return (
    <div
      style={{
        width: "100%",
        // textAlign: !fileContent && "center",
        // maxWidth: "90%",
        // backgroundColor: !fileContent && "#EAEDED",
        // height: "90%",
        // borderRadius: "20px",
        // marginTop: "10px",
        // marginLeft: "30px",
      }}
    >
      {/* <div style={{height:"100%",backgroundColor:"pink"}}>

      <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5467337913252726"
         data-ad-slot="8101520442"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>

          </div> */}
      {!fileContent && !isLoading && (
        <>
          {!file && (
            <div className={styles.boxContent}>
              {/* <div className={styles.tryButton}>
                    <a>Try for Free</a>
                    <input
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleFileUpload2}
                                    className={styles.fileInput}
                                    id="file-upload"
                                />
                    </div>      */}
              <div>
                <label htmlFor="file-upload" className={styles.fileLabel}>
                  <div className={styles.uploadIcon}></div>
                  <span className={styles.textFile}>SELECT FILE</span>
                  <span>Add {supported_types.map((item) => {
                    return (
                      <>{item.name}, </>
                    )
                  })} files</span>
                  <input
                    type="file"
                    accept={isSelectedMath ? '.jpg, .jpeg, .png, .pdf' : '.pdf,.doc,.docx'}
                    onChange={handleFileUpload2}
                    className={styles.fileInput}
                    id="file-upload"
                  />
                  <div style={{ fontSize: "12px", color: "#555", display: "flex", justifyContent: "center", gap: "5px" }} >
                    Supported format:
                    <>{supported_types.map((item) => {
                      return (
                        <div style={{ ...smallTabStyle, backgroundColor: item.color }} key={item.name} >{item.name}</div>
                      )
                    })}</>

                    {/* <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }} >PDF</div>
                    <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }} >Docx</div> */}
                  </div>
                </label>

              </div>
            </div>
          )}
          {/* <input
            type="file"
            id="fileInput"
            accept=".pdf, .doc, .docx"
            style={{ display: "none" }}
            onChange={handleFileUpload2}
          /> */}
        </>

      )}
      {file && !isLoading && !response && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            borderRadius: "5px",
            backgroundColor: "#e0e0e0",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <strong>Selected File:</strong> {file.name}
        </div>
      )}

      {(response && isSelectedMath === true) && (
        // <div
        //   style={{
        //     marginTop: "20px",
        //     padding: "10px",
        //     border: "1px solid #ddd",
        //     borderRadius: "5px",
        //     backgroundColor: "#fff",
        //     overflow: "auto",
        //     maxHeight: "100%",
        //   }}
        // >
        //   {response}
        // </div>

        <div

          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#fff",
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
          ref={inputRef}

        >
          {response}
        </div>
      )}


      {(response && isSelectedMath === false) && (
        // <div
        //   style={{
        //     marginTop: "20px",
        //     padding: "10px",
        //     border: "1px solid #ddd",
        //     borderRadius: "5px",
        //     backgroundColor: "#fff",
        //     overflow: "auto",
        //     maxHeight: "100%",
        //   }}
        // >
        //   {response}
        // </div>

        <div

          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#fff",
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
          ref={inputRef}

          dangerouslySetInnerHTML={{
            __html: renderHtmlContent(response) || "",

          }}
        // dangerouslySetInnerHTML={{ __html: response }}
        />
      )}

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "55vh",
          }}
        >
          <img src={GearGif} alt="Loading..." style={{ width: "160px", height: "160px" }} />
          <div>Processing File...</div>
        </div>
      ) : (
        <div className={styles.Errorandword} style={{ padding: "20px", overflowY: "auto" }}>
          {response && (
            <>
              {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "2em",
                  }}
                > */}
              <div className={styles.wordCount} style={{ color: wordsCount > 1000 ? "red" : "" }}>
                Word Count: {wordsCount}/1000
              </div>
              {uploadError !== "" && (
                <div
                  className={styles.wordCount}
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "18px",
                  }}
                >
                  {uploadError}
                </div>
              )}
              {/* <div></div> */}
              {/* </div> */}
              <Box

                className={styles.Box}
                sx={{
                  position: "relative",
                  // bottom: 5,
                  // left: { xs: "5%", md: "70%" },
                  display: "flex",
                  gap: "5px",
                  // width: { xs: "90%", md: "20%" },
                  borderRadius: "12px",
                  backgroundColor: "#B2BABB",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <ShareLinkComponent response={response} fileName={fileName} />

                <Tooltip title="Copy to Clipboard">
                  <IconButton onClick={() => handleCopy(response)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownloadDOC(response)}>
                    <DownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {/* <Menu
                  anchorEl={anchorEl}
                  open={dropdown}
                  onClose={handleCloseMenu}

                >
                  <MenuItem onClick={handleDownloadPDF}>Download .pdf</MenuItem>
                  <MenuItem onClick={() => handleDownloadDOC(response )}>
                    Download .doc
                  </MenuItem>
                </Menu> */}
              </Box>
            </>
          )}
        </div>
      )}

      {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
    </div>
  );
}

export default FileUploadComponent;
