import { FileIcon } from 'lucide-react'
import styles from './Workoption.module.css'
import FileUploadComponent from '../Products/Summeriser/FileUploadComponent'
import SummaryContainer from '../Home/SummaryContainer/SummaryContainer'
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate } from 'react-router-dom';

import sourceImg from "../../assets/Vector.svg"
export default function Workoption({
  hasMath,
  setHasMath,
  file,
  setFile,
  fileUrl,
  setFileUrl,
  setUserFile,
  userFile,
  sourceFileName,
  setSourceFileName,
  assigFileName,
  setAssigFileName,
  fileName,
  setFileName
}) {
  const { logout, isAuthenticated, loginWithRedirect, loginWithPopup } = useAuth0();
  const navigate = useNavigate();

  const [useOCR, setUseOCR] = useState([])

  const toggleOCR = (name) => {
    if (name.trim() !== "" && !useOCR.includes(name)) {
      const updatedUseOCR = [...useOCR, name];
      setUseOCR(updatedUseOCR);
    }
    console.log(useOCR, "dii")
  };

  function handleFileUpload3(e) {
    e.preventDefault();
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile); // Update the state
    setFileUrl(URL.createObjectURL(uploadedFile)); // Generate URL for preview
    console.log("File uploaded in handleFileUpload3:", uploadedFile);
  }
  function handleFileUpload5(e) {
    e.preventDefault();
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile); // Update the state
    setFileUrl(URL.createObjectURL(uploadedFile)); // Generate URL for preview
    console.log("File uploaded in handleFileUpload5:", uploadedFile);
  }

  function handleFileUpload4(e) {
    e.preventDefault();
    const uploadedFile = e.target.files[0]; // Correct index to 0 for the second input
    setUserFile(uploadedFile); // Update the state
    console.log("File uploaded in handleFileUpload4:", uploadedFile);
  }
  const smallTabStyle = {
    paddingLeft: "5px",
    paddingRight: "5px",
    Margin: "10px",
    borderRadius: "20px",
  };

  // useEffect(() => {
  //   console.log("useEffect triggered:", { file, userFile, hasMath });

  //   if (file && hasMath) {
  //     console.log("Navigating with a single file for Math.");
  //     navigate("/summariser");
  //   } else if (file && userFile && !hasMath) {
  //     console.log("Navigating with two files for non-Math.");
  //     navigate("/summariser");
  //   }
  // }, [file, userFile, hasMath]);

  const handleSubmit = () => {
    console.log("useEffect triggered:", { file, userFile, hasMath });

    if (file && hasMath) {
      console.log("Navigating with a single file for Math.");
      navigate("/summariser");
    } else if (file && userFile && !hasMath) {
      console.log("Navigating with two files for non-Math.");
      navigate("/summariser");
    }
  }

  const handleRadioChange = (event) => {
    if (hasMath) {
      setHasMath(false);
    } else {
      setHasMath(true)
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Your AI Homework Partner</h1>
        <p className={styles.subtitle}>
          Upload your document, and AI will solve your homework—
          <br />
          accurately and fast!
        </p>
        {hasMath ?
          <div className={styles.uploadbox}>
            {!file ? <div className={styles.boxContent}>
              <div>
                <div className={styles.sourceText2}>SOURCE FILE</div>
                <label
                  htmlFor="file-upload-1" // Unique id for the first file input
                  className={styles.fileLabel}
                  onClick={() => {
                    !isAuthenticated && loginWithPopup();
                  }}
                >
                  <div className={styles.uploadIcon}></div>
                  <span className={styles.textFile3}>SELECT FILE</span>
                  <span>Add PDFS, WORD files</span>

                  {isAuthenticated && (
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png,"
                      onChange={(e) => {
                        const uploadedFile = e.target.files[0];
                        setFile(uploadedFile); // Update the state for the first file
                        console.log("File uploaded:", uploadedFile);
                        setFileName(uploadedFile.name)
                      }}
                      className={styles.fileInput}
                      id="file-upload-1" // Unique id for the input
                    />
                  )}

                  <div
                    style={{
                      fontSize: "12px",
                      color: "#555",
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    Supported format:{" "}
                    <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                      PDF
                    </div>
                    <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                      Docx
                    </div>
                  </div>
                </label>
              </div>
            </div> : <><div className={styles.sourceContainer}>
              <div className={styles.sourceText}>SOURCE FILE</div>

              <div><img className={styles.sourceImg} src={sourceImg}></img></div>
              <label>
                <input
                  type="checkbox"
                  checked={useOCR[fileName]}
                  onChange={() => toggleOCR(fileName)}
                />
                Use OCR
              </label>
              <p>{fileName.length > 10
                ? `${fileName.slice(0, 10)}...`
                : fileName}</p>
            </div>

            </>
            }
          </div>
          :
          <div className={styles.contaoner}>
            <div className={styles.uploadbox}>

              {!file ? <div className={styles.boxContent}>

                <div>
                  <div className={styles.sourceText2}>SOURCE FILE</div>
                  <label
                    htmlFor="file-upload-1" // Unique id for the first file input
                    className={styles.fileLabel}
                    onClick={() => {
                      !isAuthenticated && loginWithPopup();
                    }}
                  >
                    <div className={styles.uploadIcon}></div>
                    <span className={styles.textFile}>SELECT FILE</span>
                    <span>Add PDFS, WORD files</span>

                    {isAuthenticated && (
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) => {
                          const uploadedFile = e.target.files[0];
                          setFile(uploadedFile);
                          setSourceFileName(uploadedFile.name)
                          console.log("File uploaded:", uploadedFile.name);
                        }}
                        className={styles.fileInput}
                        id="file-upload-1" // Unique id
                      />
                    )}

                    <div
                      style={{
                        fontSize: "12px",
                        color: "#555",
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      Supported format:{" "}
                      <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                        PDF
                      </div>
                      <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                        Docx
                      </div>
                    </div>
                  </label>
                </div>
              </div> :
                <div className={styles.sourceContainer}>
                  <div className={styles.sourceText}>SOURCE FILE</div>
                  <div><img className={styles.sourceImg} src={sourceImg}></img></div>
                  <label>
                    <input
                      type="checkbox"
                      checked={useOCR[sourceFileName]}
                      onChange={() => toggleOCR(sourceFileName)}
                    />
                    Use OCR
                  </label>
                  <p>{sourceFileName.length > 10
                    ? `${sourceFileName.slice(0, 10)}...`
                    : sourceFileName}</p>
                </div>}
            </div>
            <div className={styles.uploadbox}>
              {!userFile ? <div className={styles.boxContent}>
                <div>
                  <div className={styles.sourceText3}>ASSIGNMENT FILE</div>
                  <label
                    htmlFor="file-upload-2" // Unique id for the second file input
                    className={styles.fileLabel}
                    onClick={() => {
                      !isAuthenticated && loginWithPopup();
                    }}
                  >
                    <div className={styles.uploadIcon}></div>
                    <span className={styles.textFile}>SELECT FILE</span>
                    <span>Add PDFS, WORD files</span>

                    {isAuthenticated && (
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) => {
                          const uploadedFile = e.target.files[0];
                          setAssigFileName(uploadedFile.name)
                          setUserFile(uploadedFile); // Update the state for the second file
                          console.log("Second file uploaded:", uploadedFile);
                        }}
                        className={styles.fileInput}
                        id="file-upload-2" // Unique id
                      />
                    )}

                    <div
                      style={{
                        fontSize: "12px",
                        color: "#555",
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      Supported format:{" "}
                      <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                        PDF
                      </div>
                      <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                        Docx
                      </div>
                    </div>
                  </label>
                </div>
              </div> :
                <div className={styles.sourceContainer}>
                  <div className={styles.sourceText}>ASSIGNMENT FILE</div>
                  <div><img className={styles.sourceImg} src={sourceImg}></img></div>
                  <label>
                    <input
                      type="checkbox"
                      checked={useOCR[assigFileName]}
                      onChange={() => toggleOCR(assigFileName)}
                    />
                    Use OCR
                  </label>

                  <p>{assigFileName.length > 10
                    ? `${assigFileName.slice(0, 10)}...`
                    : assigFileName}</p>
                </div>}
            </div>
          </div>
        }


        <div className={styles.questionCard}>
          <p className={styles.question}>My assignment has Mathematics</p>
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="hasMath"
                value="yes"
                defaultChecked={hasMath}
                onChange={handleRadioChange}
                className={styles.radioInput}
              />
              <span className={styles.radioText}>Yes</span>
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                name="hasMath"
                value="no"
                defaultChecked={!hasMath}
                onChange={handleRadioChange}
                className={styles.radioInput}
              />
              <span className={styles.radioText}>No</span>
            </label>
          </div>
        </div>

        <button onClick={handleSubmit} className={styles.submitButton}>
          SUBMIT
          <span className={styles.arrow}>›</span>
        </button>
      </div>
    </div>
  )
}