import { CloseOutlined } from '@mui/icons-material';
import React from 'react';

export default function Modal2({closeThis, selectedFiles, characterAnswer}) {
    console.log("ddd",selectedFiles, characterAnswer)
  return (
    
    <div className="w-full max-w-[40%] shadow-lg absolute top-[25%] left-[30%] transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white">
      <div className="bg-[#00205B] text-white p-4 flex items-center justify-between">
        <div className="flex items-center text-lg font-normal">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 2V8H20"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 13H8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 17H8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 9H9H8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {selectedFiles}
        </div>
        <div
      className="h-6 w-6 p-0 text-white hover:bg-[#00205B] hover:text-white cursor-pointer"
      onClick={ () => closeThis()}
    >
          <CloseOutlined/>
        </div>
      </div>
      <div className="p-6">
        {characterAnswer.replace(/<\/?p>/g, '')}
        {/* {characterAnswer} */}
      </div>
    </div>
  )
}