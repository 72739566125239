import { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import styles from './Homework.module.css';
import Workoption from './Workoption';
import cloudImg from '../../assets/clodImg.svg'
import generateIcon from '../../assets/Vector-2.svg'

export default function HomeworkPage({ isAuthenticated, setIsAuthenticated,
  setFileModal,
  fileModal,

  setHasMath,
  hasMath,
  setUserFile,
  file,
  setFile,
  fileUrl,
  setFileUrl,
  userFile,
  sourceFileName,
  setSourceFileName,
  assigFileName,
  setAssigFileName,
  fileName,
  setFileName
}) {
  const data = "Try for free"
  const [showUploadoption, setUploadoption] = useState(false)
  return (
    <>
      <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} data={data} setFileModal={setFileModal}
        fileModal={fileModal} />
      {showUploadoption ? <Workoption
        file={file}
        userFile={userFile}
        setFileName={setFileName}
        fileName={fileName}
        sourceFileName={sourceFileName}
        setSourceFileName={setSourceFileName}
        assigFileName={assigFileName}
        setAssigFileName={setAssigFileName}
        setFile={setFile}
        hasMath={hasMath}
        setHasMath={setHasMath}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        setUserFile={setUserFile}

      /> : <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Your Personal AI<br />
            Homework Assistant
          </h1>
          <p className={styles.subtitle}>
            Instantly find solutions to your assignments with a simple upload—<br />
            math, science, or anything!
          </p>

          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <div className={styles.card}>
                <h2>Upload</h2>
                <p>Select your source file and <br /> your assignment file</p>
                <div className={styles.uploadIcon}>
                  <div className={styles.imgcloudIcon}><img src={cloudImg} /></div>
                </div>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <div className={styles.card}>
                <h2>Customise</h2>
                <p>Modify source summary and solve math problems</p>
                <div className={styles.radioGroup}>
                  <div className={styles.radioOption}>
                    <span className={styles.radioLabel}>Summary Type</span>
                    <div className={styles.radioButtons}>
                      <label className={styles.radio}>
                        <input type="radio" name="type" defaultChecked />
                        <span>TOPIC</span>
                      </label>
                      <label className={styles.radio}>
                        <input type="radio" name="type" />
                        <span>PAGE</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <div className={styles.card}>
                <h2>Generate</h2>
                <p>Check your work and chat with your source document</p>
                <div className={styles.uploadIcon}>
                  <div className={styles.imggenerateIcon}><img src={generateIcon} /></div>
                  <p className={styles.generateText}>GENERATE</p>
                </div>
              </div>
            </div>
          </div>

          <button onClick={() => setUploadoption(!showUploadoption)} className={styles.checkButton}>
            Check Your Work Now
          </button>
        </div>
      </div>}
    </>
  )
}