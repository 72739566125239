import 'remixicon/fonts/remixicon.css'
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import html2pdf from 'html2pdf.js'
import ReactDOMServer from 'react-dom/server';
import "./Chatbot.css"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import axios from 'axios'; // Import Axios
import { Buffer } from 'buffer';
import Header from './Header';
import {
  Grid, Button, IconButton, Tooltip, Menu,
  MenuItem,
  Box,
  Alert
} from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { message } from 'antd';
import WhisperAi from './WhisperAi';
import SummaryChatbot from './SummaryChatbot';


import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';



function Chatbot({response,userFile, setUserFile,isSelectedMath, chatHistory, setChatHistory,setPdfUrls, chat , setChat,showSummaryChatbot,fileContent,fileName,  fileUrl, setFileUrl }) {
  const [searchParams] = useSearchParams();
  // console.log(searchParams);

  const code = searchParams.get("code");
  // console.log(code);
  const [Cases, setCases] = useState(["No Cases"]);


  const [name, setName] = useState("New User");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState(null);
  const [chatInput, setChatInput] = useState(null);
  const [detailedText,setDdetailedText]=useState();

  const [inputInitHeight, setInputInitHeight] = useState(0);
  const [inputValue, setInputValue] = useState('No');

  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);
  const cardRef=useRef(null);
  const [attempts, setattempts] = useState(1);
  const [chatMessages, setChatMessages] = useState([]);
  const [queries, setqueries] = useState('');
  const [handlechat, sethandlechat] = useState([]);
  const [handlechatincoming, sethandlechatincoming] = useState([]);
  const [responsemessage, setresponsemessage] = useState({ "ans": "", "file_id": ".", "summary": "", "link": "", "page": 1, "edit_link": "" });
  const [flag, setflag] = useState([0, "No"]);
  const [history, sethistory] = useState(["No previous history"]);
  
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEld, setAnchorEld] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [dropdown, setdropdown] = useState(null);
  const [dropdownsum, setdropdownsum] = useState(null);
  const divRef = useRef(null);
  const navigate = useNavigate();
  const [whisperText,setWhisperText]=useState();




  useEffect(() => {
    // console.log(history);
    if (history[0] !== "No previous history") {
      // console.log("history", history);
      const filteredConversations = history.filter((conversation) => {
        return conversation.M.index.S === chat;
      });
      // console.log(filteredConversations);


      const newChatMessages = [];

      // Add previous conversation messages and questions alternately
      for (let i = 0; i < filteredConversations.length; i++) {
        if (filteredConversations[i].M.query && filteredConversations[i].M.query.S) {
          newChatMessages.push({
            text: filteredConversations[i].M.query.S,
            type: 'outgoing'
          });
          // Add previous conversation message
          newChatMessages.push({
            text: `${filteredConversations[i].M.answer.S}\n**FILENAME IS:** ${filteredConversations[i].M.filename.S}\n**SUMMARY IS:** ${filteredConversations[i].M.s_1.S}`,
            type: 'incoming',
            summaryType: "summary_1",
            file_id: filteredConversations[i].M.file_id.S, // Assuming a default value for file_id
            link: filteredConversations[i].M.pdf_link.S, // Assuming a default value for link
            edit_link: filteredConversations[i].M.g_link.S, // Assuming a default value for edit_link
            page: filteredConversations[i].M.page_number.N // Assuming a default value for page
          });

          // Add question from conversation if available
        }
      }

      // Set the chatMessages state with newChatMessages
      setChatMessages(newChatMessages);
    }
  }, [chat, history]);

  const fetchUserData = async (email) => {
    try {
      const API_URL = 'https://verisage.ai:5000/load_all';
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "email_id": email }),
      };
      const response = await fetch(API_URL, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data && data.conversation && data.conversation.L) {
          sethistory(data.conversation.L);
        }
        if (data && data.instances && data.instances.L) {
          setCases(data.instances.L);
        }
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };




  const detailedSummaryApiCall=async(chatMessages,index)=>{

    setAnchorEl(null);
    setAnchorEld(null);
    setdropdownsum(null)

    const API_URL = 'http://16.171.193.10:5000/getContent'
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        { "email": email,
          "file_id" :chatMessages[index].file_id,
          "casename": chat.toLowerCase(),
      }),
    };

    try {
      const response = await fetch(API_URL, requestOptions);
      const parsedResponse = await response.json();
      setDdetailedText(parsedResponse);
      
      navigate("/advancedSummary",{ state: { parsedResponse} })
      // document.body.classList.toggle('show-chatbot')
      
      
    } catch (error) {
      console.log("ERROR:", error);    
    }
  }

  

  useEffect(()=>{
    if(whisperText){
      setChatInput(whisperText)
      setUserMessage(whisperText)
    }
  },[whisperText])

  useEffect(() => {
    setInputInitHeight(chatInputRef?.current?.scrollHeight);
  }, []);


  const handlebuttonclick = (value) => {
    let temp = attempts + 1;
    if (value == 'No') {


      setattempts(temp);
      const newMessages = [
        ...chatMessages,
        { text: "More", type: 'outgoing' }
      ];

      setChatMessages(newMessages);
      setInputValue("No");


    }
    else {


      const newMessages = [
        ...chatMessages,
        { text: "Yes", type: 'outgoing' }
      ];
      setChatMessages(newMessages);
      setattempts(2);
      setInputValue("Yes");
    }


    generateResponse(userMessage, temp, value);
  };





  useEffect(() => {

    setInputValue(flag[1]);

  }, [flag]);
  const generate_sum = async (id, type) => {
    const API_URL = 'https://verisage.ai:5000/summary';

    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          "id": id,
          "summary": type,
          "instance": chat,
          "email": email
        }),
      };

      setShowLoading(true)

      const response = await fetch(API_URL, requestOptions);
      const data = await response.json();




      if (data) {
        setresponsemessage({ "ans": type === "summary_2" ? "" : "", "filename": "", "id": data.file_id, "summaryType": type, "summary": data.summary, "subType": "summary" });

      }
      else {
        setresponsemessage({ "ans": "", "filename": "", "id": "", "summary": "", "subType": "summary" });
      }
      setShowLoading(false)
      sethandlechatincoming(data.summary);


    } catch (error) {
      //messageElement.classList.add('error');
      const messageElement = 'Oops! Something went wrong. Please try again.';
      // console.log(messageElement)
    } finally {
      // chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
    }


  };


  const generateResponse = async (chatElement, a, v) => {
    const API_URL = 'https://verisage.ai:5000/process_data';

    let messageElement = chatElement;


    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          "query": userMessage,
          "attempt": a,
          "response": v,
          "email": email,
          "instance": chat
        }),
      };

      setShowLoading(true)

      const response = await fetch(API_URL, requestOptions);
      const data = await response.json();




      if (data.filename.length > 0) {
        setresponsemessage({ "ans": data.answer, "filename": data.filename, summaryType: "summary_1", "id": data.file_id, "summary": data.summary, "link": data.link, "page": data.page_num, "edit_link": data.edit_link });

      }
      else {
        setresponsemessage({ "ans": "", "filename": "", "id": "", "summary": "", "link": "", "page": 1, "edit_link": "" });
      }
      setShowLoading(false)
      sethandlechatincoming(data.answer);


    } catch (error) {
      //messageElement.classList.add('error');
      messageElement = 'Oops! Something went wrong. Please try again.';
    } finally {
      // chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
    }
  };
  useEffect(() => {
    const newMessages = [
      ...chatMessages,
      { text: userMessage, type: 'outgoing' }
    ];

    setChatMessages(newMessages);
  }, [handlechat]);

  useEffect(() => {
    const p_message = {
      text: `${responsemessage.ans}, ${responsemessage.summary}`,
      type: 'incoming',
      file_id: responsemessage.id,
      link: responsemessage.link,
      edit_link: responsemessage.edit_link,
      page: responsemessage.page,
      summary: responsemessage.summary,
      subType: responsemessage.subType,
      summaryType: responsemessage.summaryType
    };
    const newMessages = [
      ...chatMessages,
      p_message
    ];

    setChatMessages(newMessages);
  }, [handlechatincoming]);

  const handleChat = () => {
        setChatInput("");
    //setUserMessage(chatInputRef.current.value.trim());
    //if (chatInputRef.current.value.trim().length > 5) {
    // setqueries(chatInputRef.current.value.trim())
    // }
    //if (!userMessage) return;
    const newMessages = [
      ...chatMessages,
      { text: userMessage, type: 'outgoing' }
    ];

    sethandlechat(newMessages);
    // console.log(newMessages);
    // console.log(chatMessages);
    setattempts(1);
    setInputValue("No");
    generateResponse(userMessage, 1, "No");
    // }, 600);
  };

  const handleInputChange = (e) => {
    chatInputRef.current.style.height = `${inputInitHeight}px`;
    chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
    setUserMessage(e.target.value);
    setChatInput(e.target.value)

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && window.innerWidth > 800) {
      e.preventDefault();
      handleChat();
      
    }
  };
  const handleApiCall = (chatMessages, type, index) => {



    generate_sum(chatMessages[index].file_id, type);

    setAnchorEl(null);
    setAnchorEld(null);
    setdropdownsum(null)

  };



  useEffect(() => {
    if (chatboxRef.current) {
      const { scrollHeight, clientHeight } = chatboxRef.current;
      const scrollPosition = scrollHeight - clientHeight - cardRef?.current?.clientHeight + 200; 
      chatboxRef.current.scrollTo(0, scrollPosition);
    }
  }, [chatMessages]);



  const handleMenuClick = (event, index) => {

    setAnchorEl(event.currentTarget);
    setdropdownsum(index)
  };
  const handleMenuClickDownload = (event, index) => {

    setAnchorEld(event.currentTarget);
    setdropdown(index)
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorEld(null);
    setdropdown(null)
    setdropdownsum(null);
  };



  function handleDownload(message, index, type) {

    message = message[index]
    const text = message.text



    const CustomHeading = ({ level, children }) => {
      // Define custom styles for different heading levels
      const headingStyles = {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: '10px',
        backgroundColor: '#D6EAF8',
        padding: '5px',
      };

      // Determine the appropriate heading level to render
      const HeadingComponent = `h${level}`;

      return (
        <HeadingComponent style={headingStyles}>
          {children}
        </HeadingComponent>
      );
    };

    // Custom component for rendering paragraphs with custom styles
    const CustomParagraph = ({ children }) => {
      // Define custom styles for paragraphs
      const paragraphStyles = {
        marginTop: '10px',
        width: '100%',
        backgroundColor: '#D6EAF8',
        padding: '5px',
      };

      return (
        <p style={paragraphStyles}>
          {children}
        </p>
      );
    };

    // Custom component for rendering list items with bullet points
    const CustomListItem = ({ children }) => {
      // Define custom styles for list items
      const listItemStyles = {
        backgroundColor: '#D6EAF8',
        padding: '5px',
        listStyleType: 'disc'
      };

      return (
        <li style={listItemStyles}>
          {children}
        </li>
      );
    };



    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";

    var html = preHtml + ReactDOMServer.renderToStaticMarkup(<CardContent ref={divRef} id={message.summaryType == "summary_2" ? "summary_2" : "summary_1"} sx={{ width: "100%" }}>

      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, textAlign: "center" }}> {message.summaryType == "summary_2" ? "Medium Summary of" : "From file"} : {message.file_id}</Typography>

      {message.summaryType == "summary_1" &&

        <>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}> Answer</Typography>
          {message.text.slice(0, message.text.indexOf('.Information can be ')).trim()}
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginTop: 1, marginBottom: 2 }}>Page No: {message.page}</Typography>
          <Typography variant='subtitle2' width="100%" style={{ width: "100%" }}>{/\*\*SUMMARY IS:\*\*(.*?)$/s.exec(message.text) ? /\*\*SUMMARY IS:\*\*(.*?)$/s.exec(message.text)[1].trim() : ''}</Typography>

        </>}

      {message.summaryType == "summary_2" && <div style={{ width: "100%" }}>


        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>Summary</Typography>

        {
          message.summary ? <Typography variant='subtitle2' width="100%" style={{ width: "100%" }}>{message.summary}</Typography>
            : <Typography variant='subtitle2' width="100%" style={{ width: "100%" }}>{/\*\*SUMMARY IS:\*\*(.*?)$/s.exec(message.text) ? /\*\*SUMMARY IS:\*\*(.*?)$/s.exec(message.text)[1].trim() : ''}</Typography>
        }

      </div>}
      {message.summaryType == "summary_3" &&



        <CardContent ref={divRef} id="summary_3" sx={{ bgcolor: "#D6EAF8", maxWidth: "600px", margin: "20px auto", padding: "10px", paddingLeft: "20px" }} >
          <div style={{
            fontSize: '24px',
            fontWeight: 'bold',
            padding: '5px',
          }} >
            Longest summary for {chatMessages[index].file_id}  is displayed.</div>
          <div className="markdown-container" style={{ width: '100%' }}>
            <ReactMarkdown
              components={{

                h1: CustomHeading,
                h2: CustomHeading,
                h3: CustomHeading,
                p: CustomParagraph,
                li: CustomListItem,
              }}


            >
              {text}

            </ReactMarkdown>
          </div>

        </CardContent>
      }





    </CardContent>) + postHtml;
    // console.log(html)
    if (type === 'doc') {
      var blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      });

      
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = url; 
      a.download = `${message.file_id}.doc`;
      a.click(); 
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
    if (type === 'pdf') {
      html2pdf().from(html).toPdf().get('pdf', function (pdf) {
      
        var blob = new Blob([pdf.output('blob')], {
          type: 'application/pdf'
        });
        
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = `${message.file_id}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
    }
    if (type === "copy") {
      const container = document.createElement('div');
      document.body.appendChild(container);
      container.innerHTML = html;


      // Create a range and select the rendered content

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = container.innerText; // Set the value to the rendered HTML content
      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        // console.log('Copy to clipboard was ' + msg);
      } catch (err) {
        // console.error('Failed to copy: ', err);
      }

      // Clean up: remove the textarea
      document.body.removeChild(textarea);
      // Clean up: remove the container
      document.body.removeChild(container);
      // Adjust the delay time as needed
      // Adjust the delay time as needed
    }
    setAnchorEl(null);
    setAnchorEld(null);
    setdropdown(null)
  }





  useEffect(() => {

    if (isHovered == false) {
      setAnchorEl(null);
      setAnchorEld(null);
    }

  }, [isHovered])




  function parseText(message) {
    // console.log("Message-parseText",message)



    let text = message.text
    // console.log(text)



    const filenameRegex = /\*\*FILENAME IS:\*\*(.*?)\*\*SUMMARY IS:\*\*/s;
    const summaryRegex = /\*\*SUMMARY IS:\*\*(.*?)$/s;



    const filenameMatch = filenameRegex.exec(text);
    const summaryMatch = summaryRegex.exec(text);

    const filename = filenameMatch ? filenameMatch[1].trim() : '';
    const summary = summaryMatch ? summaryMatch[1].trim() : '';
    const regex = /^(.*?)(?=\*\*FILENAME IS:)/s;
    const match = regex.exec(text);

    const extractedText = match ? match[1].trim() : '';


    // console.log(extractedText);



    const answers = extractedText.split(/\d+\./).filter(Boolean);
    //console.log(answers)

    const parsedAnswer = answers.map((section, index) => {
      const parts = section?.trim().split(/\d+\./);
      // console.log(parts, "101")
      return {
        title: parts[0],
        details: parts[1]?.trim(),
      };
    });




    return (

      <CardContent ref={divRef} id={message.summaryType == "summary_2" ? "summary_2" : "summary_1"} sx={{ width: "100%" }}>

        <Typography  variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, textAlign: "center",wordWrap:"" }}> {message.summaryType == "summary_2" ? "Medium Summary of" : "From file"} : {message.file_id}</Typography>

        {message.summaryType == "summary_1" &&

          <>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}> Answer</Typography>
            {message.text.slice(0, message.text.indexOf('.Information can be ')).trim()}
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginTop: 1, marginBottom: 2 }}>Page No: {message.page}</Typography>

          </>}

        <div style={{ width: "100%" }}>

          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>Summary</Typography>

          {
            message.summary ? <Typography variant='subtitle2' width="100%" style={{ width: "100%" }}>{message.summary}</Typography>
              : <Typography variant='subtitle2' width="100%" style={{ width: "100%" }}>{summary}</Typography>
          }

        </div>




      </CardContent>


    );


  }



  function DetailParseText(chatMessages, index) {
    const text = chatMessages[index].text



    const CustomHeading = ({ level, children }) => {
      // Define custom styles for different heading levels
      const headingStyles = {
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: '10px',
        backgroundColor: '#D6EAF8',
        padding: '5px',
      };

      // Determine the appropriate heading level to render
      const HeadingComponent = `h${level}`;

      return (
        <HeadingComponent style={headingStyles}>
          {children}
        </HeadingComponent>
      );
    };

    // Custom component for rendering paragraphs with custom styles
    const CustomParagraph = ({ children }) => {
      // Define custom styles for paragraphs
      const paragraphStyles = {
        marginTop: '10px',
        width: '100%',
        backgroundColor: '#D6EAF8',
        padding: '5px',
      };

      return (
        <p style={paragraphStyles}>
          {children}
        </p>
      );
    };

    // Custom component for rendering list items with bullet points
    const CustomListItem = ({ children }) => {
      // Define custom styles for list items
      const listItemStyles = {
        backgroundColor: '#D6EAF8',
        padding: '5px',
        listStyleType: 'disc'
      };

      return (
        <li style={listItemStyles}>
          {children}
        </li>
      );
    };


    return (
      <CardContent ref={divRef} id="summary_3" sx={{ bgcolor: "#D6EAF8", maxWidth: "600px", margin: "20px auto", padding: "10px", paddingLeft: "20px" }} >
        <div style={{
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '5px',
        }} >
          Longest summary for {chatMessages[index].file_id}  is displayed.</div>
        <div className="markdown-container" style={{ width: '100%' }}>
          <ReactMarkdown
            components={{

              h1: CustomHeading,
              h2: CustomHeading,
              h3: CustomHeading,
              p: CustomParagraph,
              li: CustomListItem,
            }}


          >
            {text}

          </ReactMarkdown>
        </div>

      </CardContent>
    );
  }




  // useEffect(()=>{
  //   if(respo){
  //     document.body.classList.toggle('show-chatbot')
  //   }

  // },[fileContent])



  return (
    <div>
      {/* <Header
        name={name}
        Cases={Cases}
        setChat={setChat}
        history={history}
        email={email} />

*/}

    <div> 

    

    <button className="chatbot-toggler" onClick={() => document.body.classList.toggle('show-chatbot')}>
      <span className="message-symbols"><i class="ri-message-2-line"></i></span>
      <span className="closed"><i class="ri-close-line"></i></span>
    </button>







    <SummaryChatbot showSummaryChatbot={showSummaryChatbot} response={response} userFile={userFile} setUserFile={setUserFile} isSelectedMath={isSelectedMath} chatHistory={chatHistory} setChatHistory={setChatHistory} fileName={fileName} fileContent={fileContent} fileUrl = {fileUrl} setFileUrl = {setFileUrl} /> 
  
    {/* <div className="chatbot" style={{ backgroundColor: 'white' }}>
      <header style={{ backgroundColor: '#2980B9' }}>
        <h2>VERIS</h2>
        <span className="close-btn material-symbols-outlined" onClick={() => document.body.classList.remove('show-chatbot')}>
          close
        </span>
      </header>






      <ul className="chatbox" ref={chatboxRef}>
        {chatMessages.length === 0 && (
          <li className="chat incoming">
            <p>
              Welcome to <u style={{ color: 'red' }}>Veris</u> by Verisage. Please enter your query to begin.
              <br /> 
              <br />
              If you have any questions consult the online page <a href="your_link_here" style={{ borderBottom: '1px solid red', color: 'inherit' }}>here</a>.
            </p>

          </li>
        )}


    

        {chatMessages.length > 0 && chatMessages.map((message, index) => (
          <li key={index} className={`chat ${message.type}`}>
            {message.type === 'outgoing' ? (
              <div style={{
                maxWidth: "55%",
                padding: "10px",
                borderRadius: "12px", backgroundColor: "#D7BDE2"
              }}>{message.text}</div>
            ) : (
              message.file_id &&
              <>
                <span style={{
                  color: "white",
                  backgroundColor: "#2980B9",
                  padding: "5px",
                  width: "auto",
                  height:"auto",
                  textAlign: "center",
                }}>VERIS</span>


                <Card

                  ref={cardRef}

                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}

                  sx={{
                    width: "75%", marginTop: 2, backgroundColor: "#D6EAF8",
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: 8, padding: 1
                  }}>



                  {message.summaryType == "summary_3" ? DetailParseText(chatMessages, index) : parseText(message)}



                  <Box
                    sx={{
                      position: "relative",
                      bottom: 5,
                      left: { xs: "5%", md: "65%" },
                      display: "flex",
                      gap: "5px",
                      width: { xs: "90%", md: "35%" },
                      borderRadius: "12px",
                      backgroundColor: "#B2BABB",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                    }}
                  >
                    <Tooltip title="Summaries">
                      <IconButton onClick={(e) => handleMenuClick(e, index)}>
                        <SummarizeOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(dropdownsum === index)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleApiCall(chatMessages, "summary_2", index)}>Medium Summary</MenuItem>
                      <MenuItem onClick={() => detailedSummaryApiCall(chatMessages,index)}>Detailed Summary</MenuItem>
                    </Menu>
                    {message.subType !== "summary" && (
                      <Tooltip title="Open File">

                        <IconButton onClick={() => setPdfUrls((prevPdfUrls) => 
                        
                        
                        
                        {

                        


                        return [
                          {
                            url: message.link,
                            page: message.page,
                            edit_link: message.edit_link,
                            filename: message.file_id,
                            fileId:"sampleID"
                          },
                          ...prevPdfUrls
                        ]
                      }
                        
                        
                        )}>
                          <FileOpenOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Copy to Clipboard">
                      <IconButton onClick={() => handleDownload(chatMessages, index, "copy")}>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                      <IconButton onClick={(e) => handleMenuClickDownload(e, index)}>
                        <DownloadOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id={index}
                      anchorEl={anchorEld}
                      open={Boolean(dropdown === index)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleDownload(chatMessages, index, "doc")}>Download .doc</MenuItem>
                      <MenuItem onClick={() => handleDownload(chatMessages, index, "pdf")}>Download .pdf</MenuItem>

                    </Menu>
                  </Box>
                </Card>


              </>
            )}
          </li>
        ))}
      </ul>









      <div className="chat-input">

        {showLoading && <div style={{ marginLeft: "30px" }} >Processing...</div>

        }
        
        
        <div style={{width: "100%", display: "flex", justifyContent: "space-between",marginLeft:"10px"}}>
          <WhisperAi setWhisperText={setWhisperText} />

          <div style={{width: "80%", display: "flex", justifyContent: "flex-end", gap: "20px"}} >
          <Button variant="contained"
            onClick={() => handlebuttonclick("Yes")}
            sx={{
              padding: '5px 10px', bottom: "12%", right: "5%", borderRadius: '12px', width: "120px", bgcolor: "#2980B9"
            }}>
            Yes

          </Button>

          <Button variant="contained"
            onClick={() => handlebuttonclick("No")}
            sx={{
              padding: '5px 10px', bottom: "12%", right: "5%", borderRadius: '12px', width: "120px", bgcolor: "#2980B9"
            }}>
            More
          </Button>
          </div>


        </div>

        <div style={{ display: "flex", borderTop: "1px solid #ddd" }}>

          <textarea
            value={chatInput}
            placeholder="Enter a message..."
            spellCheck={false}
            ref={chatInputRef}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          ></textarea>

          <span id="send-btn" className="material-symbols-rounded" onClick={handleChat}>
            <i style={{ fontSize: "25px" }} class="ri-arrow-right-circle-line"></i>
          </span>
        </div>
      </div>


    </div> */}


    
    </div>
    </div>
  );
}

export default Chatbot;


