// import React, { useEffect, useState } from 'react';
// import Chatbot from './Components/Products/Summeriser/Chatbot';
// import AdvancedSummary from './Components/Products/Summeriser/AdvancedSummary';
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import Home from './Components/Home/Home';
// import Header from './Components/Products/Summeriser/Header';
// import Navbar from './Components/Navbar/Navbar';
// import Footer from './Components/Footer/Footer';

// import styles from './App.module.css';
// import { jwtDecode } from "jwt-decode";
// import Login from './Components/LoginPage/Login';
// import TermService from './Components/TermServices/TermService';
// import BlogsPage from './Components/Blogs/BlogsPage';
// import ContactPage from './Components/ContactPage/ContactPage';
// import PrivacyPolicyPage from './Components/TermServices/PrivacyPolicyPage';
// import AllPageee from './Components/SingleBlog/HomePage/Allpagee';
// import ReportBug from './Components/ReportBug/ReportBug';

// function App() {
//   const [password, setPassword] = useState('');
//   const [pdfUrls, setPdfUrls] = useState([]);
//   const [chat, setChat] = useState("");
//   const [fileContent, setFileContent] = useState("")
//   const [fileName,setFileName]= useState("");
//   const [showSummaryChatbot, setShowSummaryChatbot]=useState(false);
//   const [chatHistory, setChatHistory] = useState([{
//     content:"",
//     role:""
//   }]);

//   const [file, setFile] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(true);

//   const [urlStats, setUrlStats] = useState("")
//   const [response, setResponse] = useState();
//   const [isLoading, setIsLoading] = useState(false);
//   const [ocrProcessing, setOcrProcessing] = useState(false);
//   const [isSelected, setIsSelected] = useState(false);

//   const [uploadError, setuploadError] = useState("");

//   const [wordsCount, setWordsCount] = useState(0);

//   useEffect(() => {

//     // const token = localStorage.getItem('authToken');
//     // if (token) {
//     //   try {
//     //     // const decoded = jwtDecode(token);
//     //     setIsAuthenticated(true);
//     //   } catch (error) {
//     //     console.error('Token decoding failed:', error);
//     //   }
//     // }
//     // const storedId = localStorage.getItem('userID');
//     // const encryptedPassword = localStorage.getItem('userPassword');
//   }, []);

//   const handleReset = () => {
//     setWordsCount(0);
//     setuploadError("");
//     setResponse("");
//     setFileContent("");
//     setIsLoading(false);
//     setIsSelected(null);
//     setOcrProcessing(false);
//     setFileName("");
//     setShowSummaryChatbot(false);
//     setFile(null);
//     document.body.classList.remove('show-chatbot');
//     setChatHistory([{
//       content:"",
//       role:""
//     }]);
//   }

//   // const handleLogin = () => {
//   //   // Check if the entered password is correct
//   //   if (password === 'milestone-2') {
//   //     setAuthenticated(true);
//   //   } else {
//   //     alert('Incorrect password. Please try again.');
//   //   }
//   // };

//   // const handleLogout = () => {
//   //   setAuthenticated(false);
//   //   setPassword('');
//   // };

//   return (
//     <Router>
//       <div className={styles.container}>
//         <div>
//           <Routes>
//             <Route
//               path="/login"
//               element={
//                 !isAuthenticated ? (
//                   <Login
//                     isAuthenticated={isAuthenticated}
//                     setIsAuthenticated={setIsAuthenticated}
//                   />
//                 ) : (
//                   <Navigate to="/" />
//                 )
//               }
//             />
//             <Route
//               path="/"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <Home handleReset ={handleReset} urlStats={urlStats} setUrlStats={setUrlStats} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} file={file} setFile={setFile} />
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/terms-of-sevices"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <TermService isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/blogs"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <BlogsPage urlStats={urlStats} setUrlStats={setUrlStats} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/contactus"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <ContactPage/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/reportbug"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <ReportBug/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/blog/:id"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <AllPageee isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} file={file} setFile={setFile} />
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="privacypolicy"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <PrivacyPolicyPage/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/summariser"
//               element={
//                 !isAuthenticated ? (
//                   <Navigate to="/login" />
//                 ) : (
//                   <AdvancedSummary
//                   showSummaryChatbot={showSummaryChatbot}
//                   setPdfUrls={setPdfUrls}
//                   setuploadError={setuploadError}
//                   uploadError={uploadError}
//                   setWordsCount={setWordsCount}
//                   wordsCount={wordsCount}
//                   chat={chat}
//                   setChat={setChat}
//                     chatHistory={chatHistory}
//                     setIsLoading={setIsLoading}
//                     isLoading={isLoading}
//                     ocrProcessing={ocrProcessing}
//                     setOcrProcessing={setOcrProcessing}
//                     response={response}
//                     setResponse={setResponse}
//                     isSelected={isSelected}
//                     setIsSelected={setIsSelected}
//                     urlStats={urlStats}
//                     handleReset={handleReset}
//                     setUrlStats={setUrlStats}
//                     isAuthenticated={isAuthenticated}
//                     setIsAuthenticated={setIsAuthenticated}
//                     setChatHistory={setChatHistory}
//                     file={file}
//                     setFile={setFile}
//                     fileName={fileName}
//                     setFileName={setFileName}
//                     fileContent={fileContent}
//                     setFileContent={setFileContent}
//                     setShowSummaryChatbot={setShowSummaryChatbot}
//                     caseName={chat}
//                   />

//                 )
//               }
//             />
//           </Routes>
//         </div>
//         <div>
//           <Footer />
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import Chatbot from "./Components/Products/Summeriser/Chatbot";
import AdvancedSummary from "./Components/Products/Summeriser/AdvancedSummary";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import Header from "./Components/Products/Summeriser/Header";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";

import styles from "./App.module.css";
import { jwtDecode } from "jwt-decode";
import Login from "./Components/LoginPage/Login";
import TermService from "./Components/TermServices/TermService";
import BlogsPage from "./Components/Blogs/BlogsPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import PrivacyPolicyPage from "./Components/TermServices/PrivacyPolicyPage";
import AllPageee from "./Components/SingleBlog/HomePage/Allpagee";
import ReportBug from "./Components/ReportBug/ReportBug";
import { useAuth0 } from "@auth0/auth0-react";
import PageNotFound from "./Components/PageNotFound/PageNotFound"
import PopModal from "./Components/PopModal/PopModal";
import Comparison from "./Components/Comparision/Comparison";
import FileUpload from "./Components/ModalFileUpload/FileUpload";
import HomeworkPage from "./Components/HomeworkPage/HomeworkPage";

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  const [password, setPassword] = useState("");
  const [pdfUrls, setPdfUrls] = useState([]);
  const [chat, setChat] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [showSummaryChatbot, setShowSummaryChatbot] = useState(false);
  const [chatHistory, setChatHistory] = useState([
    {
      content: "",
      role: "",
    },
  ]);

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [userFile, setUserFile] = useState(null);

  const [sourceFileName, setSourceFileName] = useState('')
  const [assigFileName, setAssigFileName] = useState('')
  const [fileName2, setFileName2] = useState('')

  const [urlStats, setUrlStats] = useState("");
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [ocrProcessing, setOcrProcessing] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [uploadError, setuploadError] = useState("");
  const [selectedModel, setSelectedModel] = useState("good");

  const [wordsCount, setWordsCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [fileModal, setFileModal] = useState(false);

  const [fileBlobUrls, setFileBlobUrls] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [useOCR, setUseOCR] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedfile] = useState();

  const [hasMath, setHasMath] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowPopup(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);
  const handleClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (showSummaryChatbot === true) {
      // Add the class if it's not already present
      if (!document.body.classList.contains('show-chatbot')) {
        document.body.classList.add('show-chatbot');
      }
    } else {
      // Remove the class if it's present
      if (document.body.classList.contains('show-chatbot')) {
        document.body.classList.remove('show-chatbot');
      }
    }
  }, [showSummaryChatbot])

  const handleReset = () => {
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl);  // Cleanup the URL to prevent memory leaks
    }
    setFileUrl(null);
    setWordsCount(0);
    setuploadError("");
    setResponse("");
    setFileContent("");
    setIsLoading(false);
    setIsSelected(null);
    setOcrProcessing(false);
    setFileName("");
    setShowSummaryChatbot(false);
    setFile(null);
    setUserFile(null);
    setAssigFileName('')
    setFileName2('')
    setSourceFileName('')
    document.body.classList.remove("show-chatbot");
    setChatHistory([
      {
        content: "",
        role: "",
      },
    ]);
  };
  const callOverviewOnce = async (content) => {

    const apiLink = "https://pdfing.ai:7000/parse";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        content: content,
        category: "overview",
        length: "",
        model: selectedModel,
      }),
    };

    try {
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);
      // setuploadError("");

      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
      // document.body.classList.toggle("show-chatbot");
      setShowSummaryChatbot(true);
    } catch (error) {
      handleReset();
      setWordsCount(0);
      if (error.message === "Failed to fetch") {
        setuploadError(
          "Internet connection is lost. Please check your network."
        );
      } else {
        setuploadError("An unexpected error occurred.");
      }

      console.log("ERROR:", error);
      return;
      setIsLoading(false);
    }
  };

  return (
    <Router>
      {showPopup && <PopModal onClose={handleClose} />}
      <div className={styles.container}>
        <div className={styles.NavbarandBody}>
          {fileModal && <FileUpload files={files} setFiles={setFiles} fileNames={fileNames} setFileNames={setFileNames} fileBlobUrls={fileBlobUrls} setFileBlobUrls={setFileBlobUrls} fileModal={fileModal} setFileModal={setFileModal} isLoading2={isLoading2} setIsLoading2={setIsLoading2} />}
          <Routes>
            <Route
              path="/"
              element={
                <div className={styles.content}>
                  <Home
                    handleReset={handleReset}
                    fileModal={fileModal}
                    setFileModal={setFileModal}
                    urlStats={urlStats}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    file={file}
                    setFile={setFile}

                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}

                  />
                </div>
              }
            />
            <Route
              path="/blogs"
              element={
                <div className={styles.content}>
                  <BlogsPage
                    setFileModal={setFileModal}
                    fileModal={fileModal}
                    urlStats={urlStats}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </div>
              }
            />
            <Route
              path="/check-homework"
              element={
                <div className={styles.content}>
                  <HomeworkPage
                  useOCR={useOCR}
                  setUseOCR={setUseOCR}
                    setFileModal={setFileModal}
                    fileModal={fileModal}
                    hasMath={hasMath}
                    setHasMath={setHasMath}
                    file={file}
                    setFile={setFile}
                    userFile={userFile}
                    fileUrl={fileUrl}
                    setUserFile={setUserFile}
                    setFileUrl={setFileUrl}
                    setFileName={setFileName2}
                    fileName={fileName2}
                    sourceFileName={sourceFileName}
                    setSourceFileName={setSourceFileName}
                    assigFileName={assigFileName}
                    setAssigFileName={setAssigFileName}

                  />
                </div>
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/comparison"
                element={
                  <div className={styles.content}>
                    <Comparison
                      isLoading2={isLoading2}
                      setFileModal={setFileModal}
                      fileModal={fileModal}
                      setFileNames={setFileNames}
                      fileNames={fileNames}
                      fileBlobUrls={fileBlobUrls}
                      setIsLoading2={setIsLoading2}
                      isAuthenticated={isAuthenticated}
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  </div>
                }
              />
            </Route>
            <Route
              path="/terms-of-sevices"
              element={
                <div className={styles.content}>
                  <TermService
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </div>
              }
            />

            <Route
              path="/contactus"
              element={
                <div className={styles.content}>
                  <ContactPage />
                </div>
              }
            />
            <Route
              path="/reportbug"
              element={
                <div className={styles.content}>
                  <ReportBug />
                </div>
              }
            />
            <Route
              path="/blog/:id"
              element={
                <div className={styles.content}>
                  <AllPageee
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    file={file}
                    setFile={setFile}
                  />
                </div>
              }
            />
            <Route
              path="privacypolicy"
              element={
                <div className={styles.content}>
                  <PrivacyPolicyPage />
                </div>
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/summariser"
                element={
                  <AdvancedSummary
                    setFileModal={setFileModal}
                    useOCR={useOCR}
                  setUseOCR={setUseOCR}
                    fileModal={fileModal}
                    hasMath={hasMath}
                    userFile={userFile}
                    setUserFile={setUserFile}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    showSummaryChatbot={showSummaryChatbot}
                    callOverviewOnce={callOverviewOnce}
                    uploadedFile={uploadedFile}
                    setUploadedfile={setUploadedfile}
                    setPdfUrls={setPdfUrls}
                    setuploadError={setuploadError}
                    uploadError={uploadError}
                    setWordsCount={setWordsCount}
                    wordsCount={wordsCount}
                    chat={chat}
                    setChat={setChat}
                    chatHistory={chatHistory}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    ocrProcessing={ocrProcessing}
                    setOcrProcessing={setOcrProcessing}
                    response={response}
                    setResponse={setResponse}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    urlStats={urlStats}
                    handleReset={handleReset}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    setChatHistory={setChatHistory}
                    file={file}
                    setFile={setFile}
                    fileName={fileName}
                    setFileName={setFileName}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    setShowSummaryChatbot={setShowSummaryChatbot}
                    caseName={chat}

                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                  />
                }
              />
            </Route>
            <Route path="*" element={<PageNotFound></PageNotFound>} />
          </Routes>
        </div>
        <div className={styles.FooterFloat}>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
