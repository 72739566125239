import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pdfToText from 'react-pdftotext';
import './FileUpload.css';
import GearGif from "../../GearGif.gif";
import { Flex } from 'antd';
import { CancelOutlined, RemoveCircle, RemoveOutlined } from '@mui/icons-material';


const FileUpload = ({files, setFiles, fileNames,setFileNames,fileBlobUrls,setFileBlobUrls, fileModal, setFileModal, isLoading2, setIsLoading2, fileTexts,setFileTexts, setTopics}) => {
    const [wordCounts, setWordCounts] = useState({});
    const [useOCR, setUseOCR] = useState([]); // Track whether OCR is enabled for each file
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [one, setOne] = useState(["",""])

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Trigger the hidden file input
    };
    

    const handleFileChange = (event,index) => {
        const selectedFiles = Array.from(event.target.files);
        const updatedFileNames = [...one]; // Clone the current fileNames state

        // Loop through selected files and assign them to the next available slot
        selectedFiles.forEach((file) => {
            const nextEmptyIndex = updatedFileNames.findIndex((name) => name === ""); // Find the next empty slot
            if (nextEmptyIndex !== -1) {
                updatedFileNames[nextEmptyIndex] = file.name; // Assign file name to the empty slot
            }
        });

        setOne(updatedFileNames);

        // Update blob URLs
        const newBlobUrls = {};
        selectedFiles.forEach((file) => {
            const blobUrl = URL.createObjectURL(file);
            newBlobUrls[file.name] = blobUrl;
        });
        setFileBlobUrls((prevUrls) => ({
            ...prevUrls,
            ...newBlobUrls, // Merge new blob URLs with previous ones
        }));
    
        // Update the files array
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    
        // Update file names
        setFileNames((prevFileNames) => [
            ...prevFileNames,
            ...selectedFiles.map((file) => file.name),
        ]);
    
        // Update useOCR state
        setUseOCR((prevUseOCR) => [
            ...prevUseOCR,
            ...new Array(selectedFiles.length).fill(false),
        ]);
    };
    

    const handleRemoveFile = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);

        const newUseOCR = [...useOCR];
        newUseOCR.splice(index, 1);
        setUseOCR(newUseOCR); // Update OCR state when a file is removed

        const newFileNames = [...fileNames];
        newFileNames.splice(index, 1);
        setFileNames(newFileNames);
    };

    const toggleOCR = (index) => {
        const updatedUseOCR = [...useOCR];
        updatedUseOCR[index] = !updatedUseOCR[index];
        setUseOCR(updatedUseOCR); // Toggle OCR for the selected file
    };

    const countWords = (text) => {
        return text.split(/\s+/).length;
    };

    const callOverviewOnce = (text, wordCount) => {
        console.log("Text processed:", text);
        console.log("Word count:", wordCount);
    };
    const splitIntoTopics = (response) => {
        const topicRegex = /(<Topic\d+(\.\d+)*>[\s\S]*?)(?=<Topic\d+(\.\d+)*>|$)/g;
        const topicsArray = [];
        let match;
        while ((match = topicRegex.exec(response)) !== null) {
            topicsArray.push(match[1].trim());
        }
        return topicsArray;
    };
      const handleOpenFile = (file) => {
        window.open(URL.createObjectURL(file), '_blank');
      };

    const handleProcessFiles = async () => {
        setFileModal(!fileModal);
        setIsLoading2(true);
        console.log("Processing files started...",files);
        navigate('/comparison');

        try {
            // Process each file - either OCR or direct parsing
            const textPromises = files.map(async (file, index) => {
                try {
                    let text = '';
                    if (useOCR[index]) {
                        // OCR processing for the file
                        console.log(`Processing ${file.name} with OCR...`);
                        const formData = new FormData();
                        formData.append('pdfFile', file);

                        const response = await fetch('https://pdfing.ai:7000/ocr', {
                            method: 'POST',
                            body: formData,
                        });

                        if (response.ok) {
                            const ocrResult = await response.json(); // Assuming OCR API returns JSON
                            text = ocrResult.content || ''; // Adjust based on actual API response structure
                            console.log(`OCR returned for ${file.name}:`, text);

                            // Check if OCR text is valid
                            if (!text) {
                                console.error(`OCR returned empty text for ${file.name}`);
                            }
                        } else {
                            console.error(`OCR failed for ${file.name}:`, response.statusText);
                            throw new Error(`OCR failed for ${file.name}`);
                        }
                    } else {
                        // Direct parsing for the file
                        console.log(`Processing ${file.name} with direct parsing...`);
                        const filename = `${Date.now()}_${file.name}`
                        text = await pdfToText(file);
                        console.log(`Direct parsing returned for ${file.name}:`, text);
                    }

                    // Ensure text is processed and returned
                    return { [file.name]: text };
                } catch (error) {
                    console.error(`Error processing ${file.name}:`, error);
                    throw error; // Ensure errors are propagated
                }
            });

            // Wait until all files are processed
            console.log("Waiting for all files to be processed...");
            const texts = await Promise.all(textPromises);

            // Combine results into a single object
            const textObj = texts.reduce((acc, curr) => ({ ...acc, ...curr }), {});

            if (Object.keys(textObj).length > 0) {
                console.log("All files processed successfully, textObj:", textObj);

                // Now calling the depose API
                console.log("Calling depose API...");
                const deposeResponse = await fetch('https://pdfing.ai:7000/depose', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'topicgenerate',
                        main: textObj,
                    }),
                });

                if (deposeResponse.ok) {
                    const topics = await deposeResponse.text();
                    console.log("Depose API returned topics:", topics);

                    // Saving data to localStorage (verify textObj is correct)
                    console.log("Storing processed fileTexts and topics in localStorage...");
                    localStorage.setItem('fileTexts', JSON.stringify(textObj));
                    localStorage.setItem('topics', topics);

                    setIsLoading2(false);

                    // Navigating to comparison
                    // console.log("Navigating to comparison page...");
                    // navigate('/comparison');
                } else {
                    console.error("Depose API call failed:", deposeResponse.statusText);
                }
            } else {
                console.error("No files processed, textObj is empty.");
            }
        } catch (error) {
            console.error("Error in handleProcessFiles:", error);
        }
    };





    return (

        <div className="file-upload">
            <>
            <div style={{
                    position: "relative",
                    }}>
                    <div><h2>Select Files</h2></div>
                    <div><div style={{
                    position: "absolute",
                    top:'0',
                    right:'1%',
                    
                }} className="modal-close" onClick={() => setFileModal(!fileModal)}>×</div></div>
            </div>
            <div className="file-upload-container">
            
            {fileNames.length > 2 ? 
            <div className="file-names">
            {fileNames.map((name, index) => (
                <label className="file-upload-box">

                <div key={index} className="file-upload-box3">
                    <div>{`File ${index + 1}`}</div>
                    
                    <div>{name.length > 24 ? name.substring(0, 24) :name}</div>
                    <div>{!name && "No file chosen"  }</div>
                    <div className="file-names-selectfile">Select files</div>
                    <label>
                        <input
                            type="checkbox"
                            checked={useOCR[index]}
                            onChange={() => toggleOCR(index)}
                        />
                        Use OCR
                    </label>
                    <div className="remove-btn" onClick={() => handleRemoveFile(index)}><CancelOutlined/></div>
                </div>
                </label>
            ))}

            </div>
         : one.map((name, index) => (
                <label key={index} className="file-upload-box">
                    <input
                        type="file"
                        onChange={(e) => handleFileChange(e, index)}
                        style={{ display: "none" }}
                    />
                    <div className="file-upload-box3">
                        <div>{`File ${index + 1}`}</div>
                        <div>{name.length > 24 ? name.substring(0, 24) :name || "No file chosen"}</div>
                        <button
                            className="file-names-selectfile"
                            onClick={(e) => e.target.previousSibling.click()}
                        >
                            Select File
                        </button>
                        {name != "" &&<label>
                        <input
                            type="checkbox"
                            checked={useOCR[index]}
                            onChange={() => toggleOCR(index)}
                        />
                        Use OCR
                        </label>}
                    </div>
                </label>
            ))}

            </div>
            {/* <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.name}
                        <button className="remove-btn" onClick={() => handleRemoveFile(index)}>Remove</button>
                        <label>
                            <input
                                type="checkbox"
                                checked={useOCR[index]}
                                onChange={() => toggleOCR(index)}
                            />
                            Use OCR
                        </label>
                    </li>
                ))}
            </ul> */}
            <div style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'

                }}>
            {files.length > 0 ? (
            <button onClick={handleProcessFiles}>Process Files</button>
            ) : (
            <div></div>
            )}
            <div>
            <button
            onClick={handleButtonClick}
                
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                Add File
            </button>

            <input
                type="file"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}// Hide the input
            />
            </div>
            </div>
            </>
            
        </div>
    );
};

export default FileUpload;
