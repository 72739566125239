// // import React, { useEffect, useState } from 'react';
// // import { useWhisper } from '@chengsokdara/use-whisper';
// // import MicOffIcon from '@mui/icons-material/MicOff';
// // import MicIcon from '@mui/icons-material/Mic';

// // function WhisperAi({setWhisperText}) {
// //   const {
// //     recording,
// //     transcribing,
// //     transcript,
// //     startRecording,
// //     stopRecording,

// //   } = useWhisper({
// //     apiKey: 'sk-proj-2OdHOIJlUuzUvbQXTlTwT3BlbkFJt45yj9UMG3TowqOyGWq3', 
// //     removeSilence: true,
// //   });

// //   const [isRecording, setIsRecording] = useState(false);

// //   useEffect(()=>{
// //         setWhisperText(transcript.text);
// //   },[transcript.text])

// //   const toggleRecording = () => {
// //     if (isRecording) {
// //       stopRecording();
// //     } else {
// //       startRecording();
// //     }
// //     setIsRecording(!isRecording);
// //   };

// //   return (
// //     <div style={{width:"fit-content"}}>
// //       <div>
// //         <button onClick={toggleRecording}>{isRecording ? <><MicIcon/> Recording... </>  
// //         : <MicOffIcon/>}
        
// //         </button>
// //       </div>
// //     </div>
// //   );
// // }

// // export default WhisperAi;


// import React, { useState } from "react";

// function AudioRecorder() {
//   const [isRecording, setIsRecording] = useState(false);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [transcript, setTranscript] = useState("");

//   const handleRecordClick = async () => {
//     if (!isRecording) {
//       setIsRecording(true);

//       // Start recording
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const recorder = new MediaRecorder(stream);

//       recorder.ondataavailable = (e) => {
//         setAudioChunks((prev) => [...prev, e.data]);
//       };

//       recorder.onstop = async () => {
//         // Create a Blob from the audio chunks and send to OpenAI API
//         const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
//         const audioFile = new File([audioBlob], "audio.webm");

//         // Send to OpenAI
//         await transcribeAudio(audioFile);
//         setAudioChunks([]); // Clear chunks after sending
//       };

//       recorder.start();
//       setAudioChunks([]);
//       setMediaRecorder(recorder);
//     } else {
//       // Stop recording
//       setIsRecording(false);
//       if (mediaRecorder) {
//         mediaRecorder.stop();
//       }
//     }
//   };

//   // Function to send audio for transcription
//   const transcribeAudio = async (audioFile) => {
//     const formData = new FormData();
//     formData.append("file", audioFile);
//     formData.append("model", "whisper-1");

//     try {
//       const response = await fetch("YOUR_PROXY_API/transcribe", {
//         method: "POST",
//         headers: {
//           "Authorization": `Bearer sk-JLZaPhKudlPaWGbKZEKyuYKlMlrCsXtPtspxM-nBqFT3BlbkFJinrONh_jQ88jqjzK3Zgd7dM1g5-AbmUr4UMfxgIVwA`
//         },
//         body: formData,
//       });

//       const data = await response.json();
//       setTranscript(data.text);
//       console.log("Transcription:", data.text);
//     } catch (error) {
//       console.error("Error transcribing audio:", error);
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleRecordClick}>
//         {isRecording ? "Stop Recording" : "Start Recording"}
//       </button>
//       {/* {transcript && <p>Transcript: {transcript}</p>} */}
//     </div>
//   );
// }

// export default AudioRecorder;



import React, { useState, useRef } from 'react';
import {MicNone} from '@mui/icons-material';
import "./WhisperAi.css"

function AudioRecorder(props) {
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const uploadFile = async (filetobeuploaded) => {
        if (!filetobeuploaded) {
            alert("Please record an audio file first.");
            return;
        }

        // Prepare FormData with file and email
        const formData = new FormData();
        formData.append("file", filetobeuploaded);
        formData.append("email", "gg@gg.com"); // Set email as "gg@gg.com"

        try {
            const response = await fetch("https://pdfing.ai:7000/transcribe", {
                method: "POST",
                body: formData,
                headers: {
                    "Accept": "application/json"  // Expecting a JSON response from the server
                }
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Transcription Result:", data);
            props.setUserMessage(data.text)
            props.setChatInput(data.text)
            if (props.chatInputRef.current) {
                props.chatInputRef.current.focus();
            }
        } catch (error) {
            console.error("Error:", error);
        }

    };

    const handleRecordClick = async () => {
        if (!isRecording) {
            // Start recording
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                mediaRecorderRef.current = new MediaRecorder(stream);
                audioChunksRef.current = [];

                mediaRecorderRef.current.ondataavailable = (event) => {
                    if (event.data.size > 0) audioChunksRef.current.push(event.data);
                };

                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);

                    // Set file for upload
                    const tempAudioFile = new File([audioBlob], 'recording.mp3', { type: 'audio/mp3' });
                    setAudioFile(tempAudioFile);
                    uploadFile(tempAudioFile);
                };

                mediaRecorderRef.current.start();
                setIsRecording(true);
            } catch (error) {
                console.error("Error accessing microphone", error);
            }
        } else {
            // Stop recording
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };


    return (
        <div>
            <div onClick={handleRecordClick}>
                <MicNone fontSize="large" className={isRecording ? 'pulsing' : ''}  color={isRecording ? 'error' : 'primary'} />
            </div>
        </div>
    );
}

export default AudioRecorder;
