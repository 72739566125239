import React from 'react';

const Modal = ({filenameTouch,fileBlobUrls, isOpen, onClose, content,selectedFiles}) => {
    if (!isOpen) return null;

    console.log("i am open",selectedFiles);
    console.log(content);
    const renderHtmlContent = (htmlContent) => {
        console.log("regexing:", htmlContent);
      
        const updatedContent = htmlContent.replace(/<page>(\d+)<\/page>/g, (match, pageNum) => {      
          // Replace <page> tags with clickable spans that pass the page number
          return `<span class="page-link" style="display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            color: #0066cc;
            margin-bottom:20px;
            cursor: pointer;" onClick="window.handlePageClick(${pageNum})">Page ${pageNum}</span>`;
        });
        return updatedContent.replace(/<p>/g, '<p style="font-weight: bold; margin-bottom: 0;">');
      };
      const handlePageClick = (pageNum) => {
        const blobUrl = fileBlobUrls[filenameTouch];
        // Create the URL for opening the PDF at the specific page
        const pdfPageUrl = `${blobUrl}#page=${pageNum}`;
        // Open the PDF in a new tab
        window.open(pdfPageUrl, '_blank');
      };
      

    window.handlePageClick = handlePageClick;
    return (
        
        <div className="modal-overlay">
            <div style={{
            position: "relative",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "80%",
            maxHeight: "80%",
            overflowY: "auto",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        }}>
                <button style={{
            position: "absolute",
            top:'0',
            right:'1%'
            
        }} className="modal-close" onClick={onClose}>×</button>
                <div dangerouslySetInnerHTML={{
            __html: renderHtmlContent(content),
          }}/>
                
            </div>
        </div>
    );
};

export default Modal;
